<template>
    <div class="requests bg-light text-primary">
        <nav class="requests__nav">
            <router-link
                :to="{ path: 'incoming' }"
                active-class="nav-item--active"
                class="nav-item"
            >
                Incoming
            </router-link>
            <router-link
                :to="{ path: 'outgoing' }"
                active-class="nav-item--active"
                class="nav-item"
            >
                Outgoing
            </router-link>
        </nav>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'MyRequests',
    data() {
        return {
            animation: 'test'
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

@keyframes wipe-in-right {
    from {
        clip-path: inset(0 100% 0 0);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

[transition-style='in:wipe:right'] {
    animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-right both;
}

@keyframes wipe-in-left {
    from {
        clip-path: inset(0 0 0 100%);
    }
    to {
        clip-path: inset(0 0 0 0);
    }
}

[transition-style='in:wipe:left'] {
    animation: 2.5s cubic-bezier(0.25, 1, 0.3, 1) wipe-in-left both;
}

.test-enter-active {
    animation: wipe-in-right 1s;
}
.test2-enter-active {
    animation: wipe-in-left 1s;
}

.requests {
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden;
    &__nav {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        background: white;
        :last-child {
            text-align: right;
        }
    }

    .nav-item {
        padding: 1rem;
    }
    .nav-item--active {
        border-bottom: 2px solid $primary;
    }

    .scrollable-box {
        overflow: hidden;
    }
}
</style>
